<template>
<div id="app">
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="12" class="text-left"><h4>Edit Advertisement</h4></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CForm>

              <CInput
                  type="text"
                  label="Advertisement Name"
                  v-model="name"
                  placeholder="Type Name"
                  horizontal
              />

              <CInput
                  type="text"
                  label="Title"
                  v-model="title"
                  placeholder="Type Title"
                  :maxlength="maxTitleLength"
                  :description="maxTitleLengthDesc"
                  horizontal
              />

              <CTextarea
                    label="Body"
                    v-model="body"
                    placeholder="Type Body..."
                    :description="maxBodyLengthDesc"
                    horizontal
                    @keypress="checkLimitBody"
                    @keyup="checkLimitBody"
                    rows="5"
              />

              <b-row>
                <b-col sm="3">
                  <label for="input-small">AD Type</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select v-model="adType" :options="optAdTypes" @change="onChangeAdTypes"></b-form-select>
                </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited Group</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimgroup"
                      @change="onModifyLimGroup"
                      name="cblimgroup"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Groups:</label>
                  </b-col>
                  <b-col sm="5">
                    <multiselect @select="toggleGroupSelected" @remove="toggleGroupUnSelect" v-model="userGroup" :disabled="!isDisabledlg" :options="optGroup" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited User</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimuser"
                      @change="onModifyLimUsr"
                      name="cblimuser"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Users:</label>
                  </b-col>
                  <b-col sm="5">
                      <multiselect @select="toggleUserSelected" @remove="toggleUserUnSelect" v-model="user" :disabled="!isDisabledlu" :options="optusers" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Phone Number" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection3" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                      </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited Nationalities</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimnat"
                      @change="onModifyLiNat"
                      name="cblimuser"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Nationalities:</label>
                  </b-col>
                  <b-col sm="5">
                      <multiselect v-model="nations" :disabled="!isDisabledln" :options="optNations" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Nationalities" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection4" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                      </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Appearance Order</label>
                  </b-col>
                  <b-col sm="9">
                      <CInput type="number" min="0" v-model="appearanceOrder" />
                  </b-col>                                   
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Expiry Date</label>
                  </b-col>
                  <b-col sm="9">
                      <CInput type="date" v-model="expiryDate" @input="startDateOnChange()" />
                   </b-col>
                   <!-- <b-col sm="4">
                      <vue-timepicker @change="sttimeChange" v-model="fromTime" format="HH:mm:ss"></vue-timepicker>
                   </b-col>                   -->
              </b-row><br/>

              <CTextarea
                    label="Term & Conditions"
                    v-model="tnc"
                    placeholder="Type Term & Conditions..."
                    maxlength="180"
                    description="Maximum 180 characters"
                    horizontal
                    rows="5"
              />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Upload Image</label>
                  </b-col>
                  <b-col sm="9">
                      <input type="file" :v-model="srcFile" ref="myFile" @change="onFileChangedImg"><br/>
                      <span style="font-size:12px;">*Require (Width x Height) :  720 x 920 (Max)</span>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Upload Preview </label>
                  </b-col>
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps" :src="'data:image/png;base64,'+ imgAds " alt=""></b-img>
                  </b-col>
              </b-row><br/>

              <b-row v-if="adType == 'NEWS' || adType == 'PROMOTION'">
                <b-col sm="3">
                  <label for="input-small" >Preview Upload</label>
                </b-col>
                <b-col sm="9">
                  <input type="file" :v-model="previewFile" ref="myFile2" @change="onFileChangedImg2"><br/>
                  <span style="font-size:12px;">*Require (Width x Height) :  {{ previewSize }} (Max)</span>
                </b-col>
              </b-row><br v-if="adType == 'NEWS' || adType == 'PROMOTION'" />

              <b-row v-if="adType == 'NEWS' || adType == 'PROMOTION'">
                <b-col sm="3">
                  <label for="input-small" >Preview Image </label>
                </b-col>
                <b-col sm="9">
                  <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps2" :src="'data:image/png;base64,'+ imgPreviewAds " alt=""></b-img>
                </b-col>
              </b-row><br v-if="adType == 'NEWS' || adType == 'PROMOTION'" />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Status</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="status" :options="optStatus"></b-form-select>
                  </b-col>
              </b-row><br/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div><br/>

              <CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>
              </CRow>

              <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                    <b-button-group>
                    <router-link to="/advertise" tag="b-button">Cancel</router-link>
                    <b-button style="width:100px;" v-on:click="actionUpdate()" variant="primary">Update</b-button>
                    </b-button-group>
                  </b-col>
              </b-row><br/>

           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import TransactService from '@/api/TransactService.js';
import AdvertiseService from '@/api/AdvertiseService.js';
import VueTimepicker from 'vue2-timepicker';
import Multiselect from 'vue-multiselect';
import listOfNation from '@/assets/nationalities.json';

export default {
    name: 'edit_template',
    components: {
        Multiselect,
        VueTimepicker
    },
    data() { var date = new Date();
      return {
          id : this.$route.params.id,
          isDisabledlg:false,
          isDisabledlu:false,
          isDisabledln:false,
          spinner:false,
          maxTitleLength:200,
          maxTitleLengthDesc:"Maximum 200 chars",
          maxBodyLength:"500",
          maxBodyLengthDesc:"Maximum 500 words",
          name:'',
          title:'',
          body:'',
          cblimgroup:'',
          userGroup:[],
          optGroup:[],
          baseoptGroup:[],
          cblimuser:'',
          user:[],
          optusers:[],
          baseUserId:[],
          basePhoneNum:[],
          cblimnat:'',
          nations:[],
          optAdTypes :[],
          optNations :[],
          initusers:[],
          initGroup:[],
          initNations:[],
          optStatus:[],
          appearanceOrder:0,
          expiryDate : date.toISOString().substr(0, 10),
          tnc:'',
          srcFile:'',
          previewFile:'',
          imgType : '',
          imgAds:'',
          imgPreviewType : '',
          imgPreviewAds:'',
          defaultImageKey:'',
          adType:'',
          previewSize:'680 x 240',
          selectedFile: null,
          selectedFile2: null,
          widthPreview: 680,
          heightPreview: 240,
          image:{
            size:0,
            height:0,
            width:0
          },
          image2:{
            size:0,
            height:0,
            width:0
          },
          imgProps: {  width: 300, height: 100  },
          imgProps2: {  width: 300, height: 100  },
          status:'',
          seen:false,
          msg :'',
          color:''
        };
    },
    created () {
        this.getAdvertiseById(this.id);
        this.getConfigList();
        this.getUserGroupList();
        this.fetchUsers();
        setTimeout( () => this.getNationalityList() , 3000);
    },
    methods: {

      async actionShowImg(){
         const resp = await AdvertiseService.getAdvertiseById(this.id);
         let key    = !resp.imagePath ? null : resp.imagePath;
         if(key !== null){
          let temp   = key.split("."); let type  = temp[1];
          this.downloadAdvertiseImg(key,type,"true");
         }
         let key2   = !resp.previewImage ? null : resp.previewImage;
         if(key2 !== null) {
            let temp2 = key2.split("."); let type2 = temp2[1];
            this.downloadPreviewImg(key2, type2, "true");
         }
         else {
             this.msg   = "Advertise Image Not Defined !";
             this.color = 'info'; this.seen  = true;
             setTimeout( () =>  this.seen=false , 5000);
         }

      },

      onImageLoadFailure (event) {
            event.target.src = 'img/default.png'
      },

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
      },

      onFileChangedImg2(){
        this.selectedFile2 = event.target.files[0];
      },

      onModifyLimGroup(){
         if(this.cblimgroup=="true"){
              this.isDisabledlg= true; this.optGroup = this.baseoptGroup;
         } else {  this.isDisabledlg= false; this.optGroup=[]; }
      },

      onModifyLimUsr(){
        if(this.cblimuser=="true"){
              this.isDisabledlu= true;
         } else {  this.isDisabledlu= false; }
      },

      onModifyLiNat(){
        if(this.cblimnat == "true"){
              this.isDisabledln= true; this.getNationalityList();
        } else {  this.isDisabledln= false; this.optNations =[]; }
      },

      checkLimitBody(){
          let count = (this.adType === 'NEWS') ? this.body.split(" ").length : this.body.length
          let limitBody = this.maxBodyLength
          if(count > limitBody) {
              let restBody = limitBody - count
              console.log(restBody)
              if(this.adType === 'NEWS') {
                  this.body = this.body.split(" ").slice(0, restBody).join(" ")
              } else {
                  this.body = this.body.substring(0, this.maxBodyLength)
              }
          }
      },

      toggleGroupSelected(){
      },

      toggleGroupUnSelect(){
      },

      toggleUserUnSelect(){
      },

      toggleUserSelected(){
      },

      startDateOnChange(){
      },

      onChangeAdTypes() {
        if(this.adType === 'NEWS') {
          this.maxBodyLength = 500
          this.maxBodyLengthDesc = "Maximum 500 words"
          this.maxTitleLength = 200
          this.maxTitleLengthDesc = "Maximum 200 chars"
        } else {
          this.maxBodyLength = 250
          this.maxBodyLengthDesc = "Maximum 250 characters"
          this.maxTitleLength = 40
          this.title = this.title.substring(0, this.maxTitleLength)
          this.maxTitleLengthDesc = "Maximum 40 chars"
          this.checkLimitBody()
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      actionUpdate(){
        this.spinner = true;
        let params = {};  var validate = [];
        var listUser = []; var listGroup = []; var listNat = [];

        if(this.stringToBoolean(this.cblimgroup) === true ){
            var userGroup = this.userGroup;
            for (var key in userGroup) {
               var groupId = userGroup[key].id; listGroup.push(groupId);
            }
        }

        if(this.stringToBoolean(this.cblimuser) === true ){
            var user = this.user;
            for (var key2 in user) {
               var phone = user[key2].name; listUser.push(phone);
            }
        }

        if(this.stringToBoolean(this.cblimnat) === true ){
            var nat = this.nations;
            for (var key3 in nat) {
               var natkey = nat[key3].id; listNat.push(natkey);
            }
        }

        params['allowedUserGroups'] = listGroup;
        params['allowedUsers'] = listUser;
        params['allowedNationalities'] = listNat;
        params['body'] = this.body;
        params['adType'] = this.adType;
        params['appearanceOrder'] = (this.status === "INACTIVE") ? 0 : this.appearanceOrder;
        params['expiryDate'] = this.expiryDate+"T23:59:59";
        params['limitedToUserGroups'] = this.stringToBoolean(this.cblimgroup);
        params['limitedToUsers'] = this.stringToBoolean(this.cblimuser);
        params['limitedToNationalities'] = this.stringToBoolean(this.cblimnat);
        params['name'] = this.name;
        params['recordStatus'] = this.status;
        params['termsAndConditions'] = this.tnc;
        params['title'] = this.title;

        var checkKeys = ["body","expiryDate","name","termsAndConditions","title"];
        for (var key in params) {
          if(checkKeys.includes(key)){
            if(!params[key]) { validate.push(false); this.msg   = 'Data Not Complete!';
            } else { validate.push(true); }
          }
        }

        if(this.selectedFile && this.selectedFile2){
          setTimeout( () => this.onUploadUploadImg() , 5000);
        } else if(!this.selectedFile && this.selectedFile2){
          setTimeout( () => this.onUploadUploadImg2() , 5000);
        } else if(this.selectedFile && !this.selectedFile2) {
          setTimeout( () => this.onUploadUploadImg() , 5000);
        }
        setTimeout( () => this.finalSubmit(validate,params) , 7000);
      },

      isValidImage(){
          if(this.image.width === 720 && this.image.height <= 920 )  return true;
          return false;
      },

      isValidImageNews(){
          if(this.selectedFile2) {
            if (this.image2.width === 680 && this.image2.height <= 240) return true;
            else return false;
          }else return true;
      },

      isValidImagePromo(){
          if(this.selectedFile2) {
            if (this.image2.width === 680 && this.image2.height <= 240) return true;
            else return false;
          } else return true;
      },

      finalSubmit(validate,params){
            if(!validate.includes(false)){   console.log(params);
                if(this.selectedFile){
                    if((this.adType === 'POPUP' && this.isValidImage()) || (this.adType === 'NEWS' && this.isValidImageNews()) || (this.adType === 'PROMOTION' && this.isValidImagePromo())){
                        this.updateAdvertise(this.id,params);
                    } else {
                        this.color = 'warning'; this.seen  = true;
                        if(this.adType === 'POPUP') {
                            this.msg = "Failed, detected dimension " + this.image.width + " * " + this.image.height;
                        } else {
                            this.msg = "Failed, detected dimension image preview " + this.image2.width + " * " + this.image2.height;
                        }
                        setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
                    }
                } else {
                    this.updateAdvertise(this.id,params);
                }
            } else {
              this.color = 'warning'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
            }
      },

      clearForm(){
          this.name = '';
          this.title = '';
          this.body = '';
          this.cblimgroup = '';
          this.userGroup='';
          this.cblimuser = '';
          this.user='';
          this.cblimnat='';
          this.nations='';
          this.expiryDate = new Date().toISOString().substr(0, 10);
          this.tnc = '';
          this.selectedFile=null;
          this.selectedFile2=null;
      },

      async onUploadUploadImg() {
          if(this.selectedFile) {
              let file = this.$refs.myFile.files[0];
              if (!file || file.type.indexOf('image/') !== 0) return;

              this.image.size = file.size;
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = evt => {
                let img = new Image();
                img.onload = () => {
                  this.image.width = img.width;
                  this.image.height = img.height;
                }
                img.src = evt.target.result;
              }
              reader.onerror = evt => {
                console.error(evt);
              }
          }
          if (this.adType === 'NEWS' || this.adType === 'PROMOTION') {
              let file2 = this.$refs.myFile2.files[0];
              if (!file2 || file2.type.indexOf('image/') !== 0) return;

              this.image2.size = file2.size;
              let reader2 = new FileReader();
              reader2.readAsDataURL(file2);
              reader2.onload = evt => {
                let img2 = new Image();
                img2.onload = () => {
                  this.image2.width = img2.width;
                  this.image2.height = img2.height;
                }
                img2.src = evt.target.result;
              }
              reader2.onerror = evt => {
                console.error(evt);
              }
          }
      },

      async onUploadUploadImg2() {
          let file2 = this.$refs.myFile2.files[0];
          if (!file2 || file2.type.indexOf('image/') !== 0) return;

          this.image2.size = file2.size;
          let reader2 = new FileReader();
          reader2.readAsDataURL(file2);
          reader2.onload = evt => {
            let img2 = new Image();
            img2.onload = () => {
              this.image2.width = img2.width;
              this.image2.height = img2.height;
            }
            img2.src = evt.target.result;
          }
          reader2.onerror = evt => {
            console.error(evt);
          }
      },

      async saveImage(){
            const formData = new FormData()
            formData.append('resource', this.selectedFile, this.selectedFile.name);
            try {
                const resp = await AdvertiseService.uploadAdvertiseImg(this.id,formData);   //console.log(resp);
                if(resp.statusCode==="S0000"){
                      this.msg   = 'Update Advertise Success';
                      this.color = 'success';
                      this.seen  = true;
                      this.actionShowImg();
                      this.$refs.myFile.value =null;
                      this.selectedFile = null;
                      if (this.selectedFile2) {
                        setTimeout( () =>  this.savePreview(), 600);
                      }
                      //this.clearForm();
                } else {
                    this.msg   =  !resp.data.message ? "Success Updated Advertise but Image Failed!" : resp.data.message;
                    this.color = 'danger'; this.seen  = true;
                }
                this.spinner = false;
                setTimeout( () =>  this.seen=false , 5000);
            } catch (error) { console.log(error); }
      },

      async savePreview() {
          const formData2 = new FormData()
          formData2.append('resource', this.selectedFile2, this.selectedFile2.name);
          const resp2 = await AdvertiseService.uploadAdvertiseImgPreview(this.id,formData2);   //console.log(resp);
          if(resp2.statusCode==="S0000"){
            this.msg   = 'Update New Advertise Success';
            this.color = 'success';  this.seen  = true;
            this.actionShowImg();
            this.$refs.myFile2.value =null;
            this.selectedFile2=null;
          } else {
            this.msg   = !resp2.message ? 'Success Updated Advertise, but failed to save preview image' : resp2.message;
            this.color = 'danger';  this.seen  = true;
          }
          this.spinner = false;
          setTimeout( () =>  this.seen=false , 5000);
      },

      getAdvertiseById: function(id) {
        AdvertiseService.getAdvertiseById(id).then(resp => {  //console.log(resp);
            this.name = resp.name;
            this.title = resp.title;
            this.body = resp.body;
            this.cblimgroup  = resp.limitedToUserGroups;
            if(resp.limitedToUserGroups){
               this.isDisabledlg = true;
            }

            this.cblimuser   = resp.limitedToUsers;
            if(resp.limitedToUsers){
               this.isDisabledlu = true;
            }

            this.cblimnat   = resp.limitedToNationality;
            if(resp.limitedToNationality){
               this.isDisabledln = true;
            }

            this.appearanceOrder = resp.appearanceOrder;
            var expiryDate = resp.expiryDate.split("T");
            this.expiryDate = expiryDate[0];
            this.tnc  = resp.termsAndConditions;
            this.initusers   = resp.allowedUsers;
            this.initGroup   = resp.allowedUserGroups;
            this.initNations = resp.allowedNationalities;
            this.status = resp.recordStatus;
            this.adType = resp.adType;

            this.actionShowImg();

        }, error => {
          this.loading = false;
        });
      },

      downloadAdvertiseImg: function(key,type,show) {
          AdvertiseService.downloadAdvertiseImg(key,type).then(resp => {  //console.log(resp);

              if(show==="true"){
                  this.imgAds = resp;  this.imgType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a");
                  a.href = "data:image/png; base64," + ImageBase64;
                  a.download = key; a.click();
              }

          }, error => {
            console.log('error');
          });
      },

      downloadPreviewImg: function(key,type,show) {
          AdvertiseService.downloadAdvertiseImg(key,type).then(resp => {  //console.log(resp);

              if(show==="true"){
                  this.imgPreviewAds = resp;  this.imgPreviewType = type;
              } else {
                  var ImageBase64 = resp;
                  var a = document.createElement("a");
                  a.href = "data:image/png; base64," + ImageBase64;
                  a.download = key; a.click();
              }

          }, error => {
            console.log('error');
          });
      },

      updateAdvertise: function(id,params) {
          AdvertiseService.updateAdvertise(id,params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.id = resp.payload.id;
                if(this.selectedFile){
                  this.saveImage();
                } else {
                    if(this.selectedFile2){
                        this.savePreview();
                    }else {
                        this.msg = 'Update Advertise Success';
                        this.color = 'success';
                        this.seen = true;
                        //this.clearForm();
                        this.spinner = false;
                    }
                }
              } else {
                this.msg   = !resp.data.message ? "Update Advertise Failed!" : resp.data.message;
                this.color = 'warning'; this.seen  = true; this.spinner = false;
              }

              setTimeout( () =>  this.seen=false , 5000);
           }, error => {
            console.log('error');
          });
      },

      getNationalityList(){
          var list = listOfNation; var nations = []; var nations = [];
          for (var key in list) {
                var name  = list[key];
                nations.push({ id : key, name : name });
          }

          this.optNations = nations;

          for (var key2 in this.initNations) {
              var natId = this.initNations[key2];
              let index = nations.findIndex(nations => nations.id === natId );
              if(index != -1){
                 this.nations.push(this.optNations[index]);
              }
          }
      },

      getUserGroupList: function() {
        TransactService.getUserGroupList().then(resp => {  //console.log(resp);

            var items = resp; var wallets = []; var temp = [];
            for (var key in items) {
                var GroupId   = items[key].id;
                var GroupName = items[key].name;
                var GroupUId  = items[key].groupUniqueNumber;
                temp.push(GroupName+"|"+GroupUId+"|"+GroupId);
            }

            temp.sort();

            for (var key2 in temp) {
                var value = temp[key2].split("|");
                var name = value[0] +' ('+ value[1] +') ';
                var GroupId =  value[1];
                wallets.push({ id : GroupId, name : name }); name='';

            }

            this.optGroup = wallets;
            this.baseoptGroup = wallets;

            for (var key3 in this.initGroup) {
              var groupId = this.initGroup[key3];
              let index   = wallets.findIndex(wallets => wallets.id === groupId );
              this.userGroup.push(this.optGroup[index]);
            }

        }, error => {
          this.loading = false;
        });
      },

      fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(resp => {

        var users = resp.payload;
        var items = []; var userId=[]; var basePhoneNum=[];

        for (var key in users) {
            var countryCode = users[key].mobileNumber.countryCode;
            var phoneNum = users[key].mobileNumber.number;
            var ccphoneNum = countryCode+phoneNum;
            var id = users[key].id;
            items.push({  id : id, name : ccphoneNum });
            userId.push(users[key].userIdentify.userId);
            basePhoneNum.push(ccphoneNum);
        }

        this.optusers = items;
        this.baseUserId = userId;
        this.basePhoneNum = basePhoneNum;

        for (var key2 in this.initusers) {
            var phoneNum  = this.initusers[key2];
            let index     = items.findIndex(items => items.name === phoneNum);   //console.log(index +'-'+ phoneNum);
            this.user.push(this.optusers[index]);
        }

        }, error => {
          this.loading = false;
        });
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {

            var exeptSts= ["ACTIVE","INACTIVE"];
            var recSts = resp.recordStatus; var recStsSrc=[];
            for (var key in recSts) {
                var recStsName = recSts[key];
                if(exeptSts.includes(recStsName)){
                recStsSrc.push({ value: recStsName , text: recStsName });
                }
            }
            this.optStatus = recStsSrc;

            let adTypeSrc=[]; var temp = [];
            let arrAd = resp.adTypes;
            for (const item of arrAd){ temp.push(item); }
            temp.sort();
            let chosen = '';
            for (let key in temp) {
              if(key == 0) chosen = temp[key];
              adTypeSrc.push({ value: temp[key] , text: temp[key] });
            }
            this.optAdTypes = adTypeSrc;
            console.log(this.optAdTypes);
          }, error => {
            console.log('error');
          });
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>